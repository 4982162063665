@tailwind base;
@tailwind components;
@tailwind utilities;

.uppy-DashboardContent-back,
.uppy-Dashboard-browse {
  color: #00b3dd;
}

.uppy-Dashboard-browse:hover,
.uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #00b3dd;
}

[data-uppy-theme='dark'] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload,
[data-uppy-theme='dark'] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:focus,
[data-uppy-theme='dark'] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:focus,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #00b3dd;
}

.tox.tox-tinymce {
  border-radius: 4px;
  border-color: #e2e8f0;
}

.tox.tox-tinymce .tox-statusbar {
  border-color: #e2e8f0;
}

.tox.tox-tinymce .tox-toolbar__primary {
  border-bottom: 1px solid #e2e8f0;
  background: none;
}

.tox.tox-tinymce .tox-tbtn svg {
  fill: #636363;
}

.tox.tox-tinymce .tox-tbtn__select-label {
  color: #636363;
}

.tox.tox-silver-sink.tox-tinymce-aux .tox-collection__item {
  padding: 1px;
}

.tox.tox-silver-sink.tox-tinymce-aux .tox-collection__item-icon {
  height: 34px;
  width: 34px;
}

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eeeeee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  cursor: col-resize;
}

@layer base {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer components {
  .react-fine-uploader-gallery-dropzone svg {
    display: inline-block;
    margin-top: -7px;
    padding-right: 5px;
  }

  .react-fine-uploader-file-input,
  .react-fine-uploader-gallery-file-input-content,
  .react-fine-uploader-gallery-file-input-container {
    min-width: 150px;
    padding-right: 20px;
  }

  .custom-chart-button path {
    stroke-width: 0.5;
  }

  .react-phone-input-container .form-control:focus {
    --tw-ring-color: #00b3dd;
    border: #00b3dd;
  }

  .react-tel-input .country-list .search {
    padding: 10px 6px 6px 10px;
  }

  .react-tel-input .country-list .search .search-emoji {
    display: none;
  }

  .react-tel-input .country-list .search .search-box {
    width: 100%;
    margin-left: 0;
    padding: 6px 9px;
  }

  @media print {
    .highcharts-contextbutton {
      display: none;
    }
  }

  .details-section h1 {
    font-size: 17pt;
  }

  .details-section h2 {
    font-size: 16pt;
  }

  .details-section h3 {
    font-size: 15pt;
  }

  .details-section p {
    font-size: 14pt;
  }

  .details-section code,
  .details-section ncode {
    padding: 6px;
    margin: 0;
    font-size: 1em;
    background-color: #eeeeee;
    font-family: monospace;
    border-radius: 2px;
    display: block;
  }

  .details-section ol {
    list-style-type: decimal;
    padding: revert;
    line-height: 110%;
  }

  .details-section ul {
    list-style-type: disc;
    padding: revert;
    padding-left: 45px;
    text-indent: -5px;
    line-height: 110%;
  }

  .details-section a {
    color: #1e88e5;
  }

  .details-section a:hover {
    text-decoration: underline;
  }

  .detection-control-levels-coverage-pills div span {
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-bottom: 0.125rem;
    padding-top: 0.125rem;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .detection-control-levels-coverage-pills div span.untested {
    color: rgb(113 128 150 / var(--tw-text-opacity));
    background-color: #e2e8f0;
  }

  .status-pills-notcompleted,
  .status-pills-cancelled,
  .status-pills-failed,
  .detection-control-levels-coverage-pills div span.none {
    color: #ce112d;
    background-color: #ce112d33;
  }

  .status-pills-partial,
  .status-pills-running,
  .detection-control-levels-coverage-pills div span.partial {
    color: #f09b42;
    background-color: #f09b424d;
  }

  .changelog-latest-tag,
  .status-pills-completed,
  .status-pills-success,
  .detection-control-levels-coverage-pills div span.full {
    color: #6ba539;
    background-color: #6ba53926;
  }

  .status-pills-queued,
  .div span {
    color: #337ab7;
    background-color: #3379b733;
  }

  .animate-spin.animate-reverse {
    animation-direction: reverse;
  }
}

@layer utilities {
  /* clears the ‘X’ from search inputs in Chrome, Edute & Internet Explorer */
  input[type='search'].input-decoration-none::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search'].input-decoration-none::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search'].input-decoration-none::-webkit-search-decoration,
  input[type='search'].input-decoration-none::-webkit-search-cancel-button,
  input[type='search'].input-decoration-none::-webkit-search-results-button,
  input[type='search'].input-decoration-none::-webkit-search-results-decoration,
  input.input-decoration-none::-webkit-calendar-picker-indicator {
    display: none;
  }

  input.input-decoration-inset::-webkit-calendar-picker-indicator,
  input.input-decoration-inset::-webkit-inner-spin-button {
    position: relative;
    right: 1rem;
  }
}
